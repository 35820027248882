<template>
  <div>
    <div style="padding-left: 30%; padding-right: 30%; padding-top: 10vh">
      <img :src="`/img/logos/${host}.png`" alt="" style="width: 50%">
      <h2 style="text-align: center">{{ $t('login.newPassword') }}</h2>
      <el-form ref="ruleForm" :model="ruleForm" status-icon :rules="rules" label-width="160px">
        <el-form-item :label="$t('login.login_password')" prop="pass">
          <el-input v-model="ruleForm.pass" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('login.login_confirm_password')" prop="checkPass">
          <el-input v-model="ruleForm.checkPass" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <br>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">Enviar</el-button>
          <el-button @click="resetForm('ruleForm')">Limpiar</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Auth } from '@aws-amplify/auth'
import { mapGetters } from 'vuex'

export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('login.enterpassword')))
      } else {
        if (this.ruleForm.checkPass !== '') {
          if (value.length < 8) {
            callback(new Error(this.$t('login.minimum8')))
          } else if (!value.match(/[a-z]/)) {
            callback(new Error(this.$t('login.lowercase')))
          } else if (!value.match(/[A-Z]/)) {
            callback(new Error(this.$t('login.uppercase')))
          } else if (!value.match(/[0-9]/)) {
            callback(new Error(this.$t('login.number')))
          }
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('login.enterpasswordagain')))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error(this.$t('login.passwordsdontmatch')))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        pass: '',
        checkPass: ''
      },
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['userChangePass', 'loading']),
    host() {
      return window.location.hostname
    }
  },
  methods: {
    submitForm(formName) {
      this.$store.commit('transient/SET_LOADING', true)
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          // const { requiredAttributes } = user.challengeParam
          Auth.completeNewPassword(
            this.userChangePass,
            this.ruleForm.pass
          ).then(() => {
            this.$router.push('/')
          }).catch(e => {
            console.log(e)
            alert(e.message || e)
            this.$store.commit('transient/SET_LOADING', false)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style scoped>

</style>
